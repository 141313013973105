.bg-gradient-orange {
  background: rgb(255, 140, 0);
  background: linear-gradient(
    105deg,
    rgba(255, 140, 0, 1) 0%,
    rgba(255, 220, 18, 1) 100%
  );
}
.bg-gradient-primary {
  background: rgb(6, 138, 193);
  background: linear-gradient(98.87deg, #068ac1 16.95%, #7df9c2 157.07%);
}
.bg-gradient-blue {
  background: #002a57;
  background: linear-gradient(180deg, #002a57 0%, #087ab9 100%);
}

@media (max-width: 768px) {
  .bg-gradient-blue.approach {
    background: transparent !important;
  }
}

* {
  transition: background 0.2s;
}
.banner-bg-dark,
.banner-bg-light,
.service-bg-dark,
.service-bg-light,
.blockchain-bg,
.webdevlopment-bg,
.contact-bg,
.footer {
  background-repeat: no-repeat;
  background-size: cover;
}
.banner-bg-dark {
  background-image: url(./assets/images/banner-bg-dark.svg);
}

.banner-bg-light {
  background-image: url(./assets/images/banner-bg-light.svg);
}

.avtrBack{
  background-image: url(./assets/images/avatar-front.png);
  background-size: cover;
  background-position: center;
}

.squidBack{
  background-image: url(./assets/images/squid_front.png);
  background-size: cover;
  background-position: center;
}
.leoniBack{
  background-image: url(./assets/images/leoni_back.png);
  background-size: cover;
  background-position: center;
}
.teamImage{
  border-radius: 100%;
}

.service-bg-dark {
  background-image: url(./assets/images/service-bg-dark.png);
}
.service-bg-light {
  background-image: url(./assets/images/service-bg-light.png);
}

.blockchain-bg.dark {
  background-image: url(./assets/images/blockchain-devlopment-dark.png);
}

.blockchain-bg.light {
  background-image: url(./assets/images/blockchain-devlopment-light.png);
  background-position: center center;
}
.webdevlopment-bg.dark {
  background-image: url(./assets/images/web-devlopment-dark-bg.png);
}
.webdevlopment-bg.light {
  background-image: url(./assets/images/web-devlopment-light-bg.png);
}

.gradient-text {
  /* Fallback: Set a background color. */
  background: rgb(255, 145, 41);
  background: linear-gradient(
    105deg,
    rgba(255, 145, 41, 1) 0%,
    rgba(251, 176, 59, 1) 80%,
    rgba(251, 191, 98, 1) 100%
  );

  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.banner-bg-dark .banner-title {
  background: rgb(255, 140, 0);
  background: linear-gradient(
    180deg,
    rgba(255, 140, 0, 1) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  /* Set the background size and repeat properties. */
  background-size: 100%;
  background-repeat: repeat;

  /* Use the text as a mask for the background. */
  /* This will show the gradient as a text color rather than element bg. */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.service-card {
  transition: all 0.4s;
}
.service-card:hover,
.service-card.active {
  background-color: #43cea2;
  background: linear-gradient(90deg, #43cea2 0%, #185a9d 100%);
}

.service-card:hover h4,
.service-card.active h4 {
  color: white !important;
}

.service-card:hover svg *,
.service-card.active svg * {
  fill: white;
}

.project-card {
  background: linear-gradient(
    103.49deg,
    rgba(15, 68, 99, 0.1) 4.97%,
    rgba(0, 214, 214, 0.042) 104.68%
  );
  box-shadow: inset 61.6667px -61.6667px 61.6667px rgba(175, 185, 183, 0.1),
    inset -61.6667px 61.6667px 61.6667px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(123.333px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 30px;
  /* transition: all 0.3s; */
}

.project-card.effect:hover {
  box-shadow: inset 100.6667px -61.6667px 61.6667px rgba(175, 185, 183, 0.2),
    inset -61.6667px 100.6667px 61.6667px rgba(255, 255, 255, 0.2);
}
.project-card.effect {
  transition: all 0.3s ease-in-out;
}
.project-card.effect img {
  transform: scale(1);
  transition: all 0.3s ease-in-out !important;
}
.project-card.effect:hover img {
  transform: scale(1.1);
}
.testimonial-card {
  background: rgba(0, 0, 0, 0.033);
  box-shadow: inset 81.0667px -81.0667px 81.0667px rgba(194, 194, 194, 0.033),
    inset -81.0667px 81.0667px 81.0667px rgba(255, 255, 255, 0.033);
  backdrop-filter: blur(100%);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  border: 1px solid rgb(255, 255, 255, 0.2);
}

.team-card.dark {
  background: #2d2e30;
  box-shadow: 2.75546e-15px 45px 90px rgba(46, 49, 48, 0.24),
    inset -8.57253e-16px -14px 14px #383a3c,
    inset 8.57253e-16px 14px 14px #383a3c;
  border-radius: 20px;
}

.team-card.light {
  background: #edfaf7;
  box-shadow: inset -8.57253e-16px -14px 14px #d5e2df,
    inset 8.57253e-16px 14px 14px #f4ffff;
  border-radius: 20px;
  backdrop-filter: blur(11px);
}

.contact-bg {
  background-image: url(./assets/images/Contact-bg.png);
}

.whats-next {
  background: linear-gradient(
    198.68deg,
    #00b2d3 -4.89%,
    #068ac1 15.87%,
    rgba(4, 5, 5, 0.1) 83.02%
  );
}

.footer.dark {
  background-image: url(./assets/images/footer-bg-dark.png);
}
.footer.light {
  background-image: url(./assets/images/footer-bg-light.png);
}

.testimonial-mobile .owl-nav {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.testimonial-mobile .owl-nav button {
  width: 40px;
  height: 40px;
  background-color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: black;
}
.testimonial-mobile .owl-nav button.owl-prev {
  transform: translateX(-50%);
}
.testimonial-mobile .owl-nav button.owl-next {
  transform: translateX(50%);
}
.testimonial-mobile .owl-nav button span {
  color: #2d2e30 !important;
  font-size: 30px;
}

.team .owl-nav {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}

.team .owl-nav button {
  width: 40px;
  height: 40px;
  background-color: #fff !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: black !important;
}

.team .owl-nav button span {
  font-size: 30px;
}

.team .owl-nav button.owl-prev {
  transform: translateX(-50%);
}
.team .owl-nav button.owl-next {
  transform: translateX(50%);
}

html,
body {
  margin:0;
  padding:0;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.mobile-menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* padding: 30px 10px; */
  background: #1c2024;
  box-shadow: inset 36.0949px -36.0949px 36.0949px rgba(180, 191, 194, 0.1),
    inset -36.0949px 36.0949px 36.0949px rgba(14, 13, 13, 0.1);
  backdrop-filter: blur(72.1898px);
  max-height: 0;
  transition: all 0.5s;
}

.mobile-menu.active {
  max-height: 1000px;
}
.mobile-menu.light {
  color: rgba(28, 32, 36, 1);
  background: rgba(28, 32, 36, 0.15);
  box-shadow: inset 51.6667px -51.6667px 51.6667px rgba(180, 191, 194, 0.1),
    inset -51.6667px 51.6667px 51.6667px rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(103.333px);
}


.fa-globe {
  transition: all 0.2s;
  color: #4267b2;
}

.fa-facebook:hover {
  cursor: pointer;
  transition: all 0.2s;
  color: #4267b2;
}

.fa-instagram:hover {
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  color: transparent;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  background-clip: text;
  -webkit-background-clip: text;
}

.fa-twitter:hover 
{
  cursor: pointer;  color: #1da1f2;
}

.fa-youtube:hover {
  transition: all 0.2s;
  color: red;
}